.landing {
    margin: 18px 10%;
}
.landing__banner {
    background: url('./../../images/business-card-rounded.png') 145px;
    padding: 40px;
    background-size: 100%;
    .landing__intro {
        width: 400px;
    }
}

.landing__features {
    background-color: #d4ebf2;
    padding: 20px 40px;
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }
    li {
        width: 25%;
        padding: 15px 25px 15px 0;
    }
}

.landing__recent-templates {
    padding: 20px 40px;
    background-color: #fff;
}

.landing__actions-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.tenant-alert {
    transform: translateY(0);
}

.tenant-alert-enter {
    transform: translateY(-100px);
}

.tenant-alert-enter-active {
    transform: translateY(0);
    transition: all 300ms ease-out;
}

.landing_permission-warning {
    text-align: center;
    margin: 100px;
}
