.structuredTag {
    display: flex;
    width: 100%;
}

.structuredTags {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.structuredTagsAdd {
    display: flex;
    justify-content: end;
}

.remove-button {
    color: green;
}

.structuredTags-root {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.btn {
    padding: 0;
}

.individual-structured-tag {
    display: flex;
    align-items: center;
}

.remove-button-container {
    margin-bottom: 15px;
}
