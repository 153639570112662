$color-dark-gray: #697582;
$primary-blue: #0088a9;
.footer {
    display: flex;
    position: relative;
    z-index: 1;
    border-top: 1px solid #ccc;
    background-color: #fafafa;

    .dcl-widget-print-pdf {
        .dcl-pdf-button {
            .dcl-button {
                padding: 9px 20px;
                width: 100%;
            }
            .dcl-button--alternate {
                border: none !important;
                font-weight: 600;
                color: $primary-blue;
                background-color: transparent;
                padding-right: 80px;
            }
        }
    }
    .footer__product-name {
        padding: 10px;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        overflow: hidden;
        .product-label {
            color: $color-dark-gray;
        }
        .product-name {
            font-weight: bold;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.footer__section {
    display: flex;
    flex: 1 1;
    justify-content: center;
    height: 100%;
}

.dcl-widget-canvas-history,
.dcl-widget-canvas-zoom,
.dcl-widget-preview-document {
    display: inline-block;
    height: 100%;
}

.dcl-widget-save-document {
    display: inline-block;
    margin-right: 25px;
}

.dcl-widget-preview-document {
    align-self: center;
    margin: 0 15px;
}

.dcl-action-btn--undo,
.dcl-action-btn--redo {
    padding: 10px 20px;
}

.dcl-action-btn-group .dcl-action-btn {
    margin: 0 15px;
}

.footer__section .dcl-action-btn,
.dcl-zoom,
.dcl-action-btn-group,
.dcl-canvas-history,
.footer .dcl-button--toolbar {
    height: 100%;
}

.footer__section .dcl-action-btn-group {
    display: inline-flex;
}

.footer__actions {
    padding: 10px 30px 10px 10px;
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    button,
    a {
        margin-right: 10px;
    }
}
.footer__action-dropdown {
    .dropdown-menu {
        top: calc(-100% - 60px);
        left: -70px;
        button,
        a {
            text-align: left;
        }
        .dropdown-item {
            font-weight: 600;
            &:hover {
                background: #b0d9e6;
                color: #0088a9;
            }
            button {
                font-family: inherit;
            }
        }
    }
    .footer__view-as-user {
        width: 100%;
    }
}
