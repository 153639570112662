$tick-color: #5a5c5d;
$color-light-grey: #c1c5c9;
$stroke-color: #abb6b9;

.directory__expander {
    position: absolute;
    top: 4px;
    svg {
        height: 11px !important;
        vertical-align: middle;
    }

    transform: rotate(-90deg);
    transition: all 0.3s linear;
    &.directory__expander--expanded {
        transform: rotate(0deg);
    }
}
.directory__label {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    padding-left: 10px;
    max-width: 235px;
    word-break: break-all;
    span {
        padding: 3px 9px;
        overflow-wrap: break-word;
        border: 1px transparent;
        display: inline-block;
    }
}
.directory-info {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    cursor: pointer;
    position: relative;
    .directory-info__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover .directory__actions {
            display: flex;
        }
    }
}
.inline-edit-directory {
    margin-left: 20px;
}
.new-directory {
    position: relative;
    .inline-edit-group {
        width: 100% !important;
        margin-bottom: 4px !important;
        input {
            outline: none;
            background: transparent;
            caret-color: #0088a9;
            padding: 3px;
        }
        .control-label,
        .resting-label {
            display: none;
        }
        .resting-text {
            margin-top: 0;
            p {
                display: flex;
                justify-content: space-between;
            }

            width: 100%;
        }
        .resting-text.saved p::after {
            display: none;
        }
        .right-addon {
            align-items: center;
        }
        .error-message {
            top: 30px;
            font-size: 14px;
        }
    }
    &.save--disabled {
        .right-addon {
            cursor: not-allowed;
            button:first-child {
                pointer-events: none;
                color: $tick-color;
                background-color: $color-light-grey;
                box-shadow: none;
                -webkit-text-stroke: 2px $stroke-color;
                border: 1px solid transparent;
            }
        }
    }
    .spinner {
        position: absolute;
        right: 10px;
        bottom: 2px;
    }
    .success-icon {
        position: absolute;
        right: 10px;
        bottom: -4px;
        p::after {
            font-family: FontAwesome;
            content: '\f058';
            margin-left: 5px;
            color: #35ad96;
        }
    }
}
