$primary-blue: #0088a9;

.export-template.mobile-tablet {
    padding: 0 8px;
    cursor: pointer;
    .btn {
        padding: 0;
    }
    .dropdown {
        .dropdown-menu {
            position: absolute;
            left: -160px;
            min-width: 220px;
        }
    }
}
.export-template {
    .dropdown {
        .dropdown-menu {
            left: -30px;
            min-width: 220px;
        }
    }
}
.export-dropdown {
    .btn.dropdown-toggle {
        border: none !important;
        color: $primary-blue !important;
        &:hover,
        &:active {
            background: transparent !important;
        }
    }
}
.export-dropdown,
#joy-ride__template-export {
    &:focus {
        outline: none !important;
    }
}
