$color-light-grey: #e1e6ea;

.preview__thumbnail {
    position: relative;
    user-select: none;
    display: inline-flex;
    vertical-align: middle;
    z-index: 2;
    width: 100%;
    height: 100px;
    min-width: 100px;
    &:hover {
        > .preview__thumbnail-hover {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &.preview__thumbnail-no-preview {
        &:hover {
            > .preview__thumbnail-hover {
                opacity: 0;
                cursor: not-allowed;
            }
            .ij-icon {
                cursor: not-allowed;
            }
        }
    }
    img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
    .ij-icon {
        margin: auto;
    }
}
.catalog-new {
    .preview__thumbnail {
        border: 1px inset $color-light-grey;
        .spinner {
            position: absolute;
            left: calc(50% - 19px);
            top: calc(50% - 19px);
        }
    }
}
.preview__thumbnail-hover {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    &.preview__thumbnail-no-preview {
        cursor: not-allowed;
    }
}

.modal-body {
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.modal .preview__thumbnail-modal.modal-dialog {
    .modal-body {
        min-height: 300px;
    }
}

.preview__thumbnail-image img {
    max-width: 100%;
    user-select: none;
}
