$clipart-hover-bg: #eaeaea;

.clipart-item {
    padding: 10px;
    width: 64px;
    height: 64px;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }
    &:hover {
        background: $clipart-hover-bg;
        cursor: pointer;
    }
}
