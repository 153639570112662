@import '../../mixins';

$color-dark-gray: #697582;
$color-light-grey: #e1e6ea;
$color-white: #fff;
$color-sky-blue: #00b5e2;

.tabs {
    @extend %centered-designer-panel;

    height: calc(100% - 120px);
    left: 0;
    margin-top: 10px;
    top: 97px;
}

.tabs--hidden {
    display: flex;
    align-items: flex-start;
    height: 95%;
    left: -490px;
}

.tabs__items {
    overflow: hidden;
    pointer-events: auto;
    user-select: none;
    margin-top: 0;
}

.tabs__content {
    border-radius: 4px;
    width: 300px;
    height: 100%;
    background: $color-white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
    pointer-events: auto;
    overflow: hidden;
}

.content-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    &.content-panel--hidden {
        display: none;
    }
}

.content-panel__title {
    @extend %panel-title;

    margin-bottom: 20px;
}

.placeholder-label {
    margin-bottom: 0;
}

.content-panel__add-button {
    flex: none;
    z-index: 1;
    cursor: default;
    user-select: none;
    margin: 10px 0 10px;
}

.tabs-hide-button {
    @extend %hide-button;

    left: 0;
    transform: rotate(90deg);
}

.tabs-hide-button--active {
    transform: rotate(270deg);
}

.tab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 30px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    transition: fill 300ms, color 300ms;
    use {
        fill: $color-dark-gray;
        color: $color-dark-gray;
        transition: fill 300ms, color 300ms, stroke 300ms;
    }
    &:hover,
    &.tab-item--active {
        .tab-item__icon-wrapper {
            background: $color-white;
        }
        .tab-item__title {
            color: $color-sky-blue;
        }
        use {
            fill: $color-sky-blue;
            color: $color-sky-blue;
        }
    }
}

.dcl-widget-edit-text {
    margin-bottom: 10px;
    overflow: auto;
}

.tab-item__title {
    margin-top: 5px;
    color: $color-dark-gray;
    font-size: 12px;
    transition: color 300ms;
}

.tab-item__icon {
    width: 48px;
    height: 48px;
}

.dcl-widget-upload-list,
.dcl-widget-edit-text,
.dcl-widget-add-shape {
    flex: 1;
    z-index: 0;
}

.dcl-widget-upload-list {
    margin-bottom: 10px;
    overflow: auto;
}

.dcl-widget-add-shape {
    margin-top: 20px;
}

.dcl-widget-add-text,
.dcl-widget-upload-button,
.dcl-widget-add-placeholder-image {
    padding: 0 30px;
}

.tab-item__info-icon {
    margin-top: 1px;
    svg {
        padding: 2px;
    }
    :hover {
        fill: $color-sky-blue;
    }
}

.tab-item__info-icon-wrapper {
    background-color: $color-light-grey;
    border: 3px solid $color-dark-gray;
}

.tab-item__info-icon-wrapper--active,
.tab-item__info-icon-wrapper:hover {
    background-color: $color-white;
    border: 3px solid $color-sky-blue;
}

.tab-item-information {
    padding-left: 1px;
}
