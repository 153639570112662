.clipart-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    .clipart-container__left-panel {
        border-right: 1px solid #ccc;
        overflow: auto;
        min-width: 250px;
    }
    .clipart-container__right-panel {
        overflow-y: auto;
        padding: 0 30px;
    }
}
