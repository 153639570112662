$color-btn-disabled: rgba(58, 65, 76, 0.5);
$color-btn-disabled-bg: rgba(58, 65, 76, 0.25);

.bulk-actions {
    display: flex;
    align-items: center;
}
.bulk-actions button {
    margin-right: 10px;
}
