.notifications {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2000;
    .snackbar {
        position: relative !important;
        margin-bottom: 20px;
    }
}
