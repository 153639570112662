$btn-bg-hover: #edf3f8;
$border-color: #e1e6ea;

.success {
    max-width: 1170px;
    margin: 0 auto;
    width: 100%;
    padding: 20px;
    p {
        margin-bottom: 20px;
    }
    .success__header {
        padding: 16px;
        background-color: white;
        border: 1px solid #c4cdd6;
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 15px;
        text-align: center;
    }
    .dropdown-menu {
        .dropdown-item .btn {
            text-align: center;
            font-weight: 400;
        }
    }
}
.success__template-info {
    padding: 16px;
    background-color: white;
    border: 1px solid #c4cdd6;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    .success__template-info-card {
        display: flex;
        flex-direction: row;
    }
    .success__template-info-card.mobile {
        flex-direction: column-reverse;
        .success__template-info-card-preview {
            width: 100%;
            margin-bottom: 30px;
            .image-carousel {
                & > div > div:first-child {
                    height: 400px !important;
                }
            }
        }
        .success__template-info-details {
            width: 100%;
            border: none;
            min-width: 0;
            border-top: 1px solid $border-color;
            .info-value {
                word-break: break-word;
            }
        }
    }
    .success__template-info-details {
        width: 50%;
        min-width: 370px;
        border-right: 1px solid $border-color;
    }
    .success__template-info-card-preview {
        display: flex;
        width: 50%;
        padding-left: 15px;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        .carousel-container {
            display: flex;
            position: relative;
            border: solid 1px #c1c5c9;
            justify-content: center;
            align-items: flex-start;
            padding: 10px;
            width: 100%;
            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
            .image-carousel {
                width: 100%;
                & > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                & > div > div:first-child {
                    height: 100% !important;
                }
            }
        }
    }
}
.success__template-info.mobile {
    display: flex;
    flex-direction: column-reverse;
}
