.version-compare__title {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.version-compare-preview {
    .card-block {
        text-align: center;
    }
}
