.import-template {
    margin: auto;
    width: 40%;
}
.import-template__input {
    width: 100%;
    margin-top: 10px;
}
.import-template__footer {
    text-align: end;
}
.import-template__cancel-btn {
    margin-right: 7px;
}
