$greyscale-alloy: #c4cdd6;

.directory {
    margin-top: 16px;
    .root-new-directory {
        margin-left: 40px;
    }
}
.directory__sub-directories {
    padding-left: 20px;
}
.directory.selected > .directory-info > .directory-info__header > .directory__label {
    font-weight: bold;
}

.directory.disabled > .directory-info > .directory-info__header > .directory__label {
    color: $greyscale-alloy;
    cursor: not-allowed;
}
