.container.configuration {
    max-width: 700px;
    padding-top: 18px;
}
.container.configuration.tablet-mobile {
    width: 90%;
}

.setting {
    display: flex;
    .form-group {
        width: 100%;
    }
    .help-block {
        margin: 0;
    }
    svg {
        margin: 0 7px 0 7px;
    }
    .setting-item__info {
        display: flex;
        flex-direction: column;
        > div:last-child {
            margin-top: 5px;
        }
    }
    .input-group-btn {
        width: auto;
    }
}

.cancel-button {
    margin-right: 10px;
}

.configuration__footer {
    display: flex;
    justify-content: flex-end;
}
.btn.btn-default.show-property {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    background: transparent;
    path:not([fill="none"]) {
        fill: #0088a9;
    }

    border: none;
    &:hover,
    &:active:hover,
    &:focus {
        background: transparent;
        border: none;
        color: transparent;
        outline: none;
    }
}
