%centered-designer-panel {
    position: absolute;
    display: flex;
    z-index: 1;
    align-self: center;
    transition: left 0.5s, right 0.5s;
    user-select: none;
    border-radius: 4px;
}

%hide-button {
    position: absolute;
    z-index: 2;
    outline: none;
    border: none;
    padding: 15px;
    background-color: transparent;
    cursor: pointer;
    bottom: 0;
    svg {
        width: 25px;
        height: 25px;
    }
}

%panel-title {
    cursor: default;
    user-select: none;
    padding: 10px 20px;
    height: 34px;
    background-color: #e5f6fd;
    color: #697582;
    font-size: 14px;
}

%page {
    position: relative;
    margin: 20px 15%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    background-color: white;
    box-shadow: 2px 2px 2px #ddd;
    overflow: hidden;
    height: 100%;
}

%page--loaded {
    > * {
        opacity: 1;
        transition: opacity 1s;
    }
    > .spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 1s;
    }
}

%page--loading {
    > * {
        opacity: 0;
    }
    > .spinner {
        opacity: 1;
    }
}
