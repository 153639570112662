.image--hidden {
    display: none;
}
.template-container {
    position: relative;
    margin: 20px 10px;
    .preview__thumbnail {
        position: absolute;
        width: calc(100% - 22px);
        margin: 40px 11px;
        height: 47%;
    }
}
