.contextual-toolbar {
    position: absolute;
    width: 100%;
    z-index: 2;
    .toolbar {
        position: relative;
        .dcl-contextual-toolbar {
            background-color: white;
            width: 100%;
            top: 0;
            transition: top 0.4s;
            border-bottom: solid 1px #ccc;
        }
        .dcl-contextual-toolbar-primary-row {
            display: flex;
            justify-content: center;
            width: 100%;
        }
        .dcl-contextual-toolbar.dcl-invisible {
            top: -55px;
            visibility: visible;
        }
        .dcl-toolbar-button:not(.active),
        .dcl-expanding-button:not(.active) {
            background-color: white;
        }
    }
    .itemtoolbar {
        position: relative;
        background-color: white;
        top: 56px;
    }
}
