$tick-color: #5a5c5d;
$color-light-grey: #c1c5c9;
$stroke-color: #abb6b9;

.template__info-row {
    display: flex;
    padding: 10px;
    .info-label {
        font-weight: bold;
        min-width: 100px;
        margin-right: 7px;
    }
    .info-value {
        display: flex;
        flex-grow: 1;

        .template-name,
        .template-description,
        .template-tags__list {
            margin-right: 10px;
        }
        .template-name,
        .template-description,
        .template-empty-value,
        .template-tags {
            word-break: break-all;
        }
        .template-name__edit,
        .template-description__edit,
        .template-tags__edit {
            cursor: pointer;
        }
        .template-tags {
            display: flex;
            .template-tags__list {
                .tag {
                    margin: 2px;
                }
            }
        }
        .inline-edit-group {
            max-width: 230px;
            input {
                outline: none;
                min-width: 230px;
                padding-top: 0;
            }
            .resting-text {
                margin-top: 0;
                border-bottom: none;
            }
            .error-message {
                top: 30px;
            }
        }
    }
    .save--disabled ~ .right-addon {
        cursor: not-allowed;
        button:first-child {
            pointer-events: none;
            color: $tick-color;
            background-color: $color-light-grey;
            box-shadow: none;
            -webkit-text-stroke: 2px $stroke-color;
            border: 1px solid transparent;
        }
    }
    .template__actions-add {
        cursor: pointer;
        svg {
            padding-top: 4px;
            margin-right: 5px;
        }
    }
}
.tag-list {
    .info-value {
        flex-direction: column;
    }
}
.no-bottom-margin {
    .info-value {
        .inline-edit-group {
            margin-bottom: 0 !important;
        }
    }
}

.legend-margin {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 10px;
}
