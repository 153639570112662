$btn-bg-hover: #edf3f8;

.template-actions-dropdown {
    display: inline-block;
}
.btn-create-pdf,
.btn-add-to-collection {
    margin-right: 20px;
}
.btn-create-pdf span {
    display: flex;
    justify-content: center;
    .create-pdf-spinner {
        margin-right: 5px;
    }
}
.btn-create-pdf:disabled {
    background-color: transparent;
    border: 1px solid #8d8d8e;
}
.btn-create-pdf--disabled {
    cursor: not-allowed;
}
.save-success__title {
    font-weight: 400;
    margin-bottom: 15px;
}
.save-success-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .save-success-buttons {
        display: flex;
        flex-direction: row;
    }
}
.save-success-actions-container.mobile {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    button {
        margin: 5px;
        flex: 1;
    }
    .template-actions-dropdown {
        display: flex;
        button {
            flex-basis: 100%;
        }
    }
    .dropdown-menu {
        width: 100%;
    }
}

.asset-url {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    .asset-url__link {
        max-width: 215px;
        margin-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .url-copy-icon {
        margin-right: 0;
        padding: 5px;
    }
    .url-copy-icon:hover {
        background: none;
        border: none;
        color: none;
    }
    .asset-url__link-wrapper {
        display: flex;
        justify-content: center;
        align-items: baseline;
    }
}
.asset-url.mobile {
    display: block;
    text-align: center;
    .asset-url__link {
        max-width: 380px;
        margin-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    a {
        display: inline-block;
        text-align: center;
    }
}
