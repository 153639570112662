.breadcrumb {
    > li {
        .breadcrumb-label {
            display: inline-block;
            vertical-align: bottom;
            line-height: 1.15;
            font-size: 15px;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.active .breadcrumb-label {
            max-width: 250px;
            font-size: 24px;
        }
        &::before {
            font-size: 20px;
            vertical-align: middle;
        }
    }
}
.breadcrumb-mobile-laptop--active {
    max-width: 215px;
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
