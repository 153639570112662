.versions {
    padding-top: 18px;
    margin: 0 10px 0 10px;
}
.versions.mobile {
    padding: 10px;
    .card-block {
        padding: 10px;
    }
}
.versions__title {
    margin-top: 0;
}
.versions__body {
    display: flex;
}
.versions__body.mobile-tablet {
    flex-direction: column-reverse;
    .template-data {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 0;
    }
    .preview__thumbnail {
        margin-bottom: 20px;
    }
    .versions-list {
        padding: 0;
        width: 100%;
    }
}
.versions__body.mobile {
    .template-data {
        margin-top: 10px;
        width: 100%;
    }
}
.versions-list {
    width: 50%;
    margin-top: 20px;
    padding-right: 10px;
}
.versions-list.mobile {
    margin-top: 10px;
    padding: 0;
    width: 100%;
}
.template-data {
    width: 50%;
    padding-left: 10px;
    .preview__thumbnail {
        width: auto;
        height: auto;
        img {
            max-height: 400px;
        }
    }
    .card {
        margin-bottom: 10px;
    }
    .accordion-body {
        padding: 8px !important;
    }
}
.template-data.mobile {
    padding-left: 0;
}

.template-data__title {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.template-data__actions {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.version-compare {
    flex: 1 1 60px;
    .preview__thumbnail {
        width: auto;
        height: auto;
        img {
            max-height: 400px;
        }
    }
    .card {
        margin-bottom: 10px;
    }
}
