$details-text-color: #0088a9;
$hover-bg-color: #edf3f8;

.version {
    &:hover,
    &.version--comparing {
        .version__actions {
            visibility: visible;
        }
    }
    &:hover {
        color: $details-text-color;
        background-color: $hover-bg-color;
    }
    &:first-of-type {
        .version__line-top {
            border-left: none;
        }
    }
    &:last-of-type {
        .version__line-bottom {
            border-left: none;
        }
    }
}
.template-version-item.version.list-group-item {
    display: flex;
    padding: 0;
    border: 0;
    text-align: left;
    color: $details-text-color;
    cursor: pointer;
    &.active {
        color: white;
        .version__version {
            border-top: solid 1px;
        }
    }
}
.version__version {
    position: relative;
    width: 105px;
    padding-left: 15px;
    padding-top: 20px;
    z-index: 9;
}
.version__line {
    position: absolute;
    right: 11px;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .version__line-top {
        border-left: 1px dashed;
        width: 1px;
        height: 25px;
    }
    .version__line-bottom {
        border-left: 1px dashed;
        width: 1px;
        height: calc(100% - 25px - 12px);
    }
    .version__circle {
        position: relative;
        border: 1px solid;
        width: 13px;
        height: 13px;
        border-radius: 6px;
        z-index: 1;
    }
}
.version__item {
    display: flex;
    padding: 10px 20px;
    border-top: solid 1px;
    width: 100%;
}

.version__details,
.version__actions {
    user-select: none;
}

.version__details {
    flex: 1;
    font-size: 12px;
    > div:not(:last-of-type) {
        margin-bottom: 5px;
    }
    .label-success {
        margin-bottom: 5px;
    }
}

.version__actions {
    display: flex;
    flex: 0 0 120px;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    visibility: hidden;
}
