
.carousel-preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & > div {
        width: 100% !important;
    }
}
.carousel-header {
    align-self: flex-start;
    text-transform: capitalize;
}
.product-preview-carousel {
    width: 100%;
    position: relative;
}
.carousel-wrapper-spinner {
    position: absolute;
    left: calc(50% - 36px);
    top: calc(50% - 36px);
}
