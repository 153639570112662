$card-height: 60vh;
$card-padding: 16px;
$color-light-grey: #c1c5c9;

.create {
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    .card-block {
        height: $card-height;
        padding: $card-padding;
    }
    .card-footer {
        display: flex;
        justify-content: flex-end;
    }
    .create__footer-info {
        padding: 12px 5px 0 0;
    }

    .incomplete-product-options {
        color: #e58628;

        span {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.create__mask-legend {
    display: flex;
    .mask-legend {
        display: flex;
        margin: 10px 15px 0 0;
    }
}
