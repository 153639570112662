$color-light-grey: #c1c5c9;
$hover-color: #d4ecf2;
$primary-blue: #0088a9;
$disabled-gray: #687582;

.cursor-pointer {
    cursor: pointer;
}
.B {
    font-weight: bold;
}
.disabled-gray {
    color: $disabled-gray;
}
.collections-list {
    font-size: 15px;
    line-height: 18px;
    height: 100%;
    .collections-list__tree {
        margin-top: 16px;
    }
    .collections-list__actions {
        display: flex;
        justify-content: space-between;
        .collections-list__actions-add svg {
            padding-top: 4px;
            margin-right: 5px;
        }
    }
    .root-new-collection {
        margin-left: 20px;
    }

    .icon-button.btn {
        padding: 2px;
        border: 0;
        background: transparent;
        line-height: 0;
        &:hover,
        &:focus,
        &:active,
        &:active:hover {
            border: 0;
        }
        .ij-icon {
            margin-right: 0;
        }
    }
    &.no-collection {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        .no-collection__body {
            text-align: center;
            margin: 100px 20px 0 20px;
            svg {
                color: $color-light-grey;
            }
            .no-collection__text {
                margin-top: 20px;
                color: $disabled-gray;
            }
            .no-collection__btnAdd {
                margin: 25px 0;
                width: 100%;
                height: 48px;
            }
        }
    }
}
.collections-list__footer .btn + .btn {
    margin-left: 5px;
}
.directory-header__button {
    border: none;
    padding: 0;
    margin-left: 14px;
    background: transparent;
    cursor: pointer;
}
.children {
    margin-left: 20px;
}
.directory-header__button.disabled {
    &:hover {
        cursor: not-allowed;
    }
}
