@import '../../mixins';

.canvas-switcher {
    @extend %centered-designer-panel;

    flex-direction: column;
    width: 160px;
    right: 30px;
    pointer-events: auto;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
    background-color: white;
}

.canvas-switcher__title {
    @extend %panel-title;
}

.canvas-switcher--hidden {
    right: -160px;
}

.dcl-widget-canvas-switcher {
    max-height: 500px;
    overflow-y: auto;
}

.canvas-switcher:empty {
    display: none;
}

.canvas-switcher-hide-button {
    @extend %hide-button;

    right: 0;
    transform: rotate(270deg);
}

.canvas-switcher-hide-button--active {
    transform: rotate(90deg);
}
