div.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

button.state-button {
    margin: 0 10px;
}

.display-canvas {
    height: 100%;
    width: 100%;
}
