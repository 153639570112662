html {
    scroll-behavior: smooth;
}

body {
    height: 100vh;
}

#root {
    height: 100%;
    > div {
        height: 100%;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ecf3f8;
    .navbar-fluid .navbar {
        margin-bottom: 0;
    }
}

.modal.in {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.full-page-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(222, 217, 217, 0.35);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-moz-scrollbar {
    width: 8px;
    height: 8px;
}

::-moz-scrollbar-track {
    background: #f1f1f1;
}

::-moz-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

::-moz-scrollbar-thumb:hover {
    background: #555;
}
