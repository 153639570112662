.recent-sku-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .recent-sku-list__sku {
        margin-right: 7px;
        margin-top: 7px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .recent-sku-list__sku,
    .recent-sku-list__sku:focus,
    .recent-sku-list__sku:active:focus {
        border-radius: 16px;
        outline: none;
    }
}
