$extended-label-color: #0088a9;

.extended-collection-names {
    color: $extended-label-color;
    cursor: pointer;
}
.tooltip-label {
    font-size: 14px;
    text-align: left;
    padding: 5px;
}
.tooltip-inner {
    max-height: 400px;
    overflow-y: auto;
}
.template-collection-names {
    margin-top: 10px;
    max-width: 120px;
    .collection-text {
        font-weight: bold;
    }
    .collection-name {
        font-style: italic;
    }
}
.table-row-template-name,
.table-row-author {
    overflow: hidden;
    max-width: 120px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.template-info-row {
    max-width: 150px;
}
.template-info-row__actions,
.template-info-row__tablet {
    .btn-link {
        text-align: left;
        font-weight: 600;
    }
    .dropdown-menu {
        right: 0;
        left: unset;
    }
}
.template-info-row__mobile-tablet {
    padding-right: 0 !important;
    .dropdown {
        outline: none;
        svg:focus {
            outline: none;
        }
        .dropdown-menu {
            left: -105px;
            li a {
                text-align: left;
            }
        }
    }
    .btn.dropdown-toggle {
        background-color: transparent !important;
        border: none !important;
        outline: none;
        &:active {
            outline: none;
            background-color: transparent !important;
        }
    }
    .caret {
        display: none;
    }
}
.select-template-checkbox-container {
    .table-row-template-name {
        max-width: 120px;
    }
}
.template-table-row td:not(:first-child):not(:last-child) {
    cursor: pointer;
}
