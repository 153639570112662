$color-light-grey: #e1e6ea;

.templates__infinite-scroll {
    min-height: 500px;
}

.templates__table-view {
    margin-top: 20px;
    td,
    th {
        border: none !important;
    }
    tr {
        border: 1px solid $color-light-grey;
    }
    .checkbox {
        margin: 0 !important;
    }
    .template-name,
    .template-author,
    .template-status {
        width: 15%;
    }
    .template-preview {
        width: 20%;
    }
    .template-select-checkbox,
    .template-status,
    .template-actions {
        width: 5%;
    }
}
.templates__table-view.mobile {
    .template-name,
    .template-author {
        width: 1%;
    }
    .template-preview {
        width: 50%;
    }
    .template-select-checkbox,
    .template-actions {
        width: 1%;
    }
}
.templates__table-view.tablet {
    .template-name {
        width: 10%;
    }
    .template-author,
    .template-status {
        width: 10%;
    }
    .template-preview {
        width: 10%;
    }
    .template-status {
        width: 10%;
    }
    .template-select-checkbox,
    .template-actions {
        width: 1%;
    }
}
