.mcp-product-selector {
    width: 100%;
    .sku-selector {
        display: flex;
    }
    .sku-selector__input {
        width: 100%;
    }
    .create__sku-info {
        padding: 10px 0 0 5px;
    }
}
