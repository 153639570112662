.designer {
    display: flex;
    flex: 1;
    position: relative;
    z-index: 0;
    margin-top: 1px;
    overflow: hidden;
    background-color: #f0f0f0;
    opacity: 1;
    transition: opacity 2s;
    .dcl-widget-canvas-actions {
        right: 200px;
        display: flex;
        position: absolute;
        align-items: center;
        height: 100%;
    }
}
.designer-full-screen {
    margin-top: 0;
}
