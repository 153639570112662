$color-light-grey: #c4cdd6;
$color-primary-btn: #0088a9;

.search-container {
    display: flex;
    align-items: flex-end;
    .search-container__search-option {
        flex-basis: 25%;
        margin-right: 15px;
        min-width: 150px;
    }
    .search-container__search-option.mobile {
        margin-right: 0;
    }
    .search-container__search-value.mobile {
        margin-right: 0;
        .btn-search-mobile {
            top: 30%;
            right: 10px;
            position: absolute !important;
            color: $color-primary-btn;
            cursor: pointer;
        }
    }
    .search-container__search-value {
        flex-basis: 75%;
        margin-right: 15px;
        position: relative;
        .search-text {
            margin-bottom: 0;
            .input-group-btn {
                width: 0;
            }
        }
    }
    .form-group {
        margin-bottom: 0;
    }
    .search-btn {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .search-btn-mobile {
        padding: 0 5px !important;
        height: 48px;
    }
    .search-btn-mobile.btn.btn-default {
        outline: none;
    }
}
.search-container.mobile {
    display: flex;
    position: relative;
}

.btn.btn-default.search-text {
    position: relative;
}
.btn.btn-default.search-clear-button {
    position: absolute;
    right: 30px;
    height: 50px;
    top: 0;
    padding: 15px;
    background: transparent;
    path:not([fill="none"]) {
        fill: #0088a9;
    }

    border: none;
    &:hover,
    &:active:hover,
    &:focus {
        background: transparent;
        border: none;
        color: transparent;
        outline: none;
    }
}
.search-clear-button {
    z-index: 3 !important;
}

.search-container__owners {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border-bottom: 1px solid $color-light-grey;
}
