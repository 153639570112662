.version-preview-details-tablet {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    .template-details-wrapper,
    .version-preview-details-wrapper {
        display: flex;
        width: 50%;
        flex-direction: column;
    }
}
.template-details-wrapper {
    margin-top: 10px;
    .template__info-row {
        padding: 10px 10px 10px 0;
    }
}
.version-preview {
    display: flex;
    width: 100%;
    min-height: 415px;  //default height of carousel image
    flex-grow: 1;
    flex-direction: column;
    .carousel-container {
        height: 100%;
        width: 100%;
        .image-carousel {
            width: 100%;
            height: auto;
            .carousel-preview-container {
                display: flex;
            }
        }
    }
}
.version-preview.mobile {
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0;
    margin-bottom: 10px;
    .image-carousel {
        & > div > div:first-child {
            height: 250px !important;
        }
    }
}
.version-preview.tablet {
    .carousel-container {
        .image-carousel {
            height: 100%;
            & > div > div:first-child {
                height: 100% !important;
            }
        }
    }
}
.template-data__actions.tablet {
    justify-content: space-around;
}
