.clipart-modal.modal-dialog {
    width: 70vw;
    min-width: 500px;
}
.clipart-modal {
    .modal-header {
        background-color: #eaeaea;
    }
    .modal-body {
        margin: 0;
        padding: 0 15px;
        height: 80vh;
    }
}
.clipart-items-spinner {
    position: absolute;
    top: 250px;
    left: 50%;
}
