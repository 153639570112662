$color-light-grey: #c1c5c9;

.template-details-modal-container {
    .modal-body {
        margin: 1px;
        padding: 1px;
        .template-details-modal-preview {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .carousel-preview-container {
                h2 {
                    margin: 0 0 5px 0;
                }
                .carousel-navigation {
                    margin-top: 5px;
                }
            }
        }
    }
}
