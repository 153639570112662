$left-panel-width: 350px;
$gutter-size: 10px;

.catalog-new {
    display: flex;
    flex-direction: row;
    overflow: auto;
    flex-grow: 1;
    padding: 20px 10px;
    .modal-backdrop {
        pointer-events: none;
    }
    .side-panel {
        width: $left-panel-width;
        overflow-y: auto;
        margin: 58px 10px 0 10px;
    }
    .catalog__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0 10px;
        .catalog__header-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .catalog__header-container-browse-collections {
                margin-bottom: 5px;
            }
            .catalog__header {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                height: 38px;
                align-items: center;
                .breadcrumb {
                    padding: 0;
                }
                .btn {
                    margin-left: 10px;
                }
            }
        }
        .catalog__main-panel {
            overflow-y: auto;
            padding: 15px;
            background-color: white;
            flex-grow: 1;
            .catalog__main-panel--actions-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
                margin-top: 10px;
                padding-bottom: 10px;
                height: 60px;
                border-bottom: solid 1px #ccc;
            }
            .catalog__main-panel-actions {
                display: flex;
                justify-content: flex-end;
                z-index: 9;
                right: 16px;
                align-items: center;
                .btn svg {
                    margin-right: 6px;
                }
                .btn {
                    align-items: center;
                    display: flex;
                }
            }
        }
        .catalog__main-panel.mobile {
            padding: 5px;
        }
    }
    .catalog__body-mobile {
        margin: 0 2%;
    }
    .catalog__body-tablet {
        margin: 0 5%;
    }
}
.catalog-new-mobile {
    padding: 0;
}
.collection-drawer {
    .drawer-header {
        display: flex;
        flex-direction: row-reverse;
        align-items: baseline;
        justify-content: space-between;
        padding: 10px 15px;
    }
    .drawer-body {
        padding: 10px 15px;
    }
}
.hide {
    display: none;
}
