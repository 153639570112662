.content-panel--shapes {
    position: relative;
}
.content-panel--shapes-container {
    overflow-y: hidden;
    height: 100%;
}
.content-panel--shapes .smart-shapes {
    height: 95%;
    padding: 5px;
}
