$color-light-grey: #c1c5c9;

.template-details {
    .template-details__divider {
        margin: 5px -20px;
        border-top: 1px solid $color-light-grey;
    }
    .drawer-header {
        padding: 2px 14px;
        h2 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .drawer-body {
        padding: 2px 20px;
        .template-details__preview {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
        }
        .template-details__actions {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            .dropdown {
                ul {
                    width: 100%;
                }
                button {
                    width: 235px;
                }
                .dropdown-item {
                    .btn {
                        font-weight: 400;
                        text-align: center;
                    }
                }
            }
        }
        .template-details__tabs {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 20px;
            .template-details__tabs-item {
                margin-right: 25px;
                button {
                    padding: 7px 10px;
                }
            }
        }
        .template-details__content {
            margin-right: -20px;
            padding-bottom: 15px;
            overflow-y: auto;
            .versions-list__items {
                .version__version {
                    width: 85px;
                    .label-info {
                        padding: 6px;
                    }
                }
                .spinner {
                    display: flex;
                    justify-content: center;
                }
            }
            .collection-list {
                h3 {
                    margin-bottom: 20px;
                    font-weight: 400;
                }
                ul > li {
                    margin: 10px;
                }
            }
        }
    }
}
