$greyscale-alloy: #c4cdd6;

.modal-dialog.collection-modal {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.modal-dialog.collection-modal.mobile {
    width: 90%;
}
.modal-dialog.collection-modal.tablet {
    width: 65%;
}

.directory-selector {
    display: flex;
    flex-direction: row;
    height: 55vh;
    .directory-info__header {
        margin-left: 8px;
    }
    .directory-list {
        padding-left: 16px;
        padding-top: 16px;
        overflow: auto;
        flex-grow: 4;
        border: solid 1px $greyscale-alloy;
        border-radius: 6px;
    }
    .selected-directory-list {
        width: 40%;
        padding-left: 40px;
        flex-basis: 40%;
        flex-grow: 1;
        .tag {
            margin: 5px 5px 5px 0;
            font-size: unset;
            color: inherit;
            button.close {
                font-size: 18px;
            }
        }
        .selected-directory-list_title {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        .selected-directory-list_description {
            margin-bottom: 15px;
        }
        .selected-directory-list_empty {
            margin-top: 100px;
            color: $greyscale-alloy;
        }
    }
    .directory.selected > .directory-info > .directory-info__header > .directory__label > span {
        border-radius: 6px;
        background-color: #edf3f8;
        font-weight: normal;
        border: solid 1px $greyscale-alloy;
        padding: 2px 9px;
    }
}
