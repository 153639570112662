.structuredTag {
    display: flex;
    width: 100%;
}

div.category {
    width: 50%;
    display: flex;
}

div.allowedValues {
    width: 100%;
    display: flex;
}
