.overlay-button {
    background: transparent;
    border: none;
    text-decoration: none;
    outline: none;
    color: #0088a9;
    height: 100%;
    text-align: left;
    padding: 12px;
}
.overlay-modal {
    .modal-content {
        position: fixed;
        width: 600px;
        height: 300px;
        left: 10px;
        bottom: 10px;
        .overlay-title {
            margin: 10px 10px 10px 0;
            font-weight: bold;
            font-size: 16px;
        }
    }
    .modal-info {
        .modal-body {
            margin-left: 0;
        }
    }
    .modal-footer {
        .overlay-actions__skip-btn:hover {
            background: none;
            border: 0;
        }
        button.btn {
            font-size: 18px;
        }
    }
    .overlay-actions__skip-btn {
        float: left;
        padding-left: 0;
        padding-right: 0;
    }
    .overlay-actions__skip-icon,
    .overlay-actions__prev-icon,
    .overlay-actions__next-icon {
        padding-top: 5px;
    }
    .overlay-actions__skip-icon,
    .overlay-actions__next-icon {
        margin-left: 5px;
    }
    .overlay-actions__prev-icon {
        margin-right: 5px;
    }
}
.overlay-modal ~ .modal-backdrop {
    opacity: 0 !important;
}
