.contact-content {
    padding: 18px 20px 20px 20px;

    .card {
        margin-bottom: 20px;
    }

    .contact-content__documentation,
    .contact-content__contact-us {
        svg {
            margin: 0 10px 0 0;
        }
    }
}

.contact-content__list {
    list-style-type: none;
    margin: 20px 0 0 20px;
    .contact-content__list-mail svg {
        margin-right: 7px;
    }
}
.slack-icon {
    width: 36px;
    margin-left: -10px;
}
