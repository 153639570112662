.tenant__wrapper {
    display: flex;
    align-items: center;
    height: 50px;
}

.tenant__button {
    background: transparent;
    border: none;
    text-decoration: none;
    outline: none;
    color: #0088a9;
    height: 100%;
    text-align: left;
}
