
.info-container {
    margin: 4px;
    overflow-y: auto;
    legend: {
        margin-bottom: 0;
    }
}

.info-container__tags {
    .info-container__tag-set {
        display: flex;
        flex-wrap: wrap;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        .tag {
            margin: 3px;
        }
    }
}

.input-tags-vertical {
    display: flex;
    flex-direction: column;
}

.input-tags {
    display: flex;
    .form-group {
        width: 100%;
        margin-bottom: 5px;
    }
    .input-tags-info {
        padding: 10px 0 0 5px;
    }
}

.info-container__tag-set .tag {
    margin-top: 3px;
    margin-right: 3px;
}

.clickable-text {
    font-size: 80%;
    margin-left: 10px;
    margin-bottom: 10px;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.info-row {
    display: flex;
    padding: 10px;
    .info-label {
        font-weight: bold;
        min-width: 100px;
        margin-right: 7px;
    }
    .info-value {
        display: flex;
        flex-grow: 1;
    }
}
