$primary-blue: #0088a9;

.filter {
    position: relative;
    cursor: pointer;
}
.filter__item {
    padding: 2px 10px;
    white-space: nowrap;
}
.filter-mobile-tablet {
    padding: 0 8px;
    .btn {
        padding: 0;
    }
    .dropdown-menu {
        right: 20px;
        left: unset;
    }
}
.filter-dropdown {
    .filter-dropdown {
        display: none;
    }
    .dropdown-menu {
        min-width: 260px;
    }
    ul.dropdown-menu {
        padding: 2px 10px;
    }
    .btn.dropdown-toggle {
        border: none !important;
        color: $primary-blue !important;
        &:hover,
        &:active {
            background: transparent !important;
        }
    }
}
.filter-dropdown,
#joy-ride__template-filter {
    &:focus {
        outline: none !important;
    }
}
