$card-height: 60vh;
$card-padding: 16px;
$color-light-grey: #c1c5c9;

.create-template {
    display: flex;
    height: 100%;
    .create__preview-toggle {
        cursor: pointer;
        position: absolute;
        right: 5px;
    }

    .create__inputs {
        display: none;
        width: 50%;
        flex-grow: 1;
        overflow-y: auto;
        &.create__inputs-show {
            display: block;
        }
        .create__product-selector {
            padding-right: 20px;
        }
        .form-group {
            width: 96%;
        }
    }
    .create__preview {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 50%;
        padding: 0 5%;
        overflow: auto;
        h2 {
            margin-top: 0;
        }
        .create__preview-image {
            display: flex;
            border: solid 1px $color-light-grey;
            justify-content: center;
            align-items: center;
            max-width: 385px;
            padding: 10px;
            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
            // override carousel style: start
            .create__preview-carousel {
                width: 100%;
                height: 100%;
                & > div {
                    display: flex;
                    flex-direction: column;
                    width: 100% !important;
                    height: 100%;
                    justify-content: center;
                }
                & > div > div:first-child {
                    height: 100% !important;
                }
            }
            // override carousel style: end
        }
        .create__preview-image-skuless {
            height: 80%;
        }
    }
    .create__footer-info {
        padding: 12px 5px 0 0;
    }
    .accordion {
        margin: 0;
        padding-right: 20px;
        .accordion-header {
            padding: 0 0 10px 0;
        }
        .accordion-body {
            padding: 0;
            .form-group {
                width: 96%;
            }
        }
    }
    .create__preview-custom-template {
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.create__surfaces {
    padding-right: 20px;
    .surface-selector-dimensions {
        display: flex;
        justify-content: space-between;
        width: 96%;
        .form-group {
            width: 100%;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
}
